export const Routes = {
  APPOINTMENT_PATIENT_INFORMATION: '/appointment/patient-information',
  APPOINTMENT_SUCCESS_SUB_PAGE: '/appointment-success',
  APPOINTMENT_SUCCESS: '/appointment/appointment-success',
  APPOINTMENTS: '/appointments',
  APPOINTMENT_DETAILS: '/appointments/:id',
  APPOINTMENT_CANCEL: '/appointments/:id/cancel',
  APPOINTMENT_FEEDBACK: '/appointments/:id/feedback',
  APPOINTMENT_RESCHEDULE: '/appointments/:id/reschedule',
  AUTH: '/auth',
  AUTH_OTP_VERIFICATION: '/auth/otp-verification',
  BOOK_DOCTOR: '/doctors/:id/book',
  BOOK: '/book',
  CHECKOUT_MEDICINES: '/pharmacies/:id/checkout',
  CLINICAL_DOCUMENTS: '/medical-records/clinical-documents',
  DASHBOARD: '/dashboard',
  DIAGNOSTIC_RESULTS_RECORDS: '/medical-records/diagnostic-results',
  DOCTORS: '/doctors',
  E_PRESCRIPTIONS: '/medical-records/e-prescriptions',
  HEALTH_DATA: '/medical-records/health-data',
  LAB_REQUESTS_RECORDS: '/medical-records/lab-requests',
  LAB_REQUESTS: '/lab-requests',
  LABORATORIES: '/laboratories',
  LOCATIONS: '/locations',
  LOGIN_AUTOMATIONS: '/login/automations',
  VERIFICATION: '/auth/verification',
  MEDICAL_RECORDS: '/medical-records',
  MEDICINE_ORDERS: '/medicine-orders',
  MY_APPOINTMENTS: '/my-appointments',
  MY_LAB_APPOINTMENTS: '/my-lab-appointments',
  MY_REFERRALS: '/my-referrals',
  PATIENT_INFORMATION_SUB_PAGE: '/patient-information',
  PHARMACIES: '/pharmacies',
  PRIVACY_POLICY: 'https://www.mwell.com.ph/privacy-policy',
  PROFILE: '/profile',
  RECORDS: '/records',
  PROFILE_EDIT: '/profile/edit',
  PROFILE_VIEW: '/profile/view',
  PROFILE_FAVOURITES: '/profile/favourites',
  PROVIDERS: '/providers',
  PROVIDERS_MAP: '/providers/maps',
  PROVIDER: '/providers/:id',
  PROVIDER_DIRECTORY_PROFILE: '/providers/directory/profile/:id',
  PROVIDER_DIRECTORY_SERVICE: '/providers/directory/profile/:id/service/:serviceId',
  PROVIDER_PAGE: '/provider/:id',
  PROVIDER_SERVICE: '/providers/[id]/service/[serviceId]',
  PROVIDER_TYPES: '/providers/types',
  PROVIDER_TYPES_SLUG: '/providers/types/[typeSlug]',
  PROVIDER_SERVICE_LIST: '/providers/:id',
  REGISTER_ONBOARDING: '/auth/register/onboarding',
  ROOT: '/',
  SCHEDULE_LAB_REQUEST: '/lab-requests/:id/book',
  SPECIALTY: '/specialty',
  TELECONSULTATION_PRIVACY_POLICY: '/teleconsultation-privacy-policy',
  TELECONSULTATION_TERMS_AND_CONDITIONS: '/teleconsultation-terms-and-conditions',
  TERMS_AND_CONDITIONS: 'https://www.mwell.com.ph/terms-and-conditions',
  REVIEW: '/review',
  PAYMENT: '/payment',
  BOOKING_CONFIRMED: '/booking-confirmed',
  CLIENT_PROFILES: '/profile/clients',
  CLIENT_POLICIES: '/profile/policies',
  CLIENT_PROFILE: '/profile/clients/[id]',
  CLIENT_PROFILE_ADD: '/profile/clients/add',
  CLIENT_PROFILE_EDIT: '/profile/clients/[id]/edit',
  PROVIDER_CLIENT_REGISTRATION: '/provider/[providerId]/registration',
  PROMOTION: '/promotions/:id',
  MESSAGES: '/messages',
  MESSAGE_THREAD: '/messages/:threadId',
  MPH_APPOINTMENTS: '/mph/appointments',
  MPH_APPOINTMENT: '/mph/appointments/:id',
  MPH_APPOINTMENT_RESCHEDULE: '/mph/appointments/:id/reschedule',
  MPH_APPOINTMENT_CANCEL: '/mph/appointments/:id/cancel',
  MPH_PROVIDERS: '/mph/providers',
  MPH_PROVIDER: '/mph/providers/:id',
  MPH_HMO: '/mph/hmo/',
  MY_HMO: '/hmo/my-hmo',
  ADD_HMO: '/hmo/add-hmo',
  VIEW_HMO: '/hmo/view-hmo',
  MAINTENANCE: '/maintenance',
  PARTNER: '/partner',
  MEMBERSHIPS: '/memberships',
};

export const publicDynamicPathsRegex = [
  /\/providers\/\d+\/service\/\d+/,
  /\/providers\/\d+/,
  /\/providers\/\S+\/service\/\d+/,
  /\/providers\/\S+/,
  /\/provider\/\S+\/registration/,
  /\/provider\/\S+\/registration\/success/,
  /\/providers\/\S+\/registration/,
  /\/providers\/\S+\/registration\/success/,
];
