import { MembersApi, UnlinkMemberDto, VerifyMemberDto } from '@healthhub/api-lib';
import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';

const membersApi = HealthHubApiClient.use(MembersApi);

export type GetMembersParams = {
  userId: string;
  limit?: number;
  page?: number;
};

export async function getMemberships(params: GetMembersParams) {
  try {
    const response = await membersApi.memberControllerGetMemberships(
      params.userId,
      params?.limit,
      params?.page,
    );

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function verifyMembership(verifyMemberDto: VerifyMemberDto) {
  try {
    return await membersApi.memberControllerVerify(verifyMemberDto);
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function unlinkMembership(unlinkMemberDto: UnlinkMemberDto) {
  try {
    return await membersApi.memberControllerUnlink(unlinkMemberDto);
  } catch (error) {
    throw new CustomError(error);
  }
}
