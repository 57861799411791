import { GetTagResponseDto } from '@healthhub/api-lib';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GET_TAGS, GET_TAGS_BY_ID } from '../../constants';
import { getTags, GetTagsParams } from '../../services';

export const useGetTags = (params?: GetTagsParams, options?: UseQueryOptions<GetTagResponseDto>) =>
  useQuery<GetTagResponseDto>([GET_TAGS, params], () => getTags(params), {
    refetchOnWindowFocus: false,
    ...options,
  });

export const useFindOneTag = (
  organizationId?: string,
  options?: UseQueryOptions<GetTagResponseDto>,
) =>
  useQuery<GetTagResponseDto>(
    [GET_TAGS_BY_ID, organizationId],
    () => getTags({ id: organizationId }),
    options,
  );
