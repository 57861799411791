import { useMutation } from '@tanstack/react-query';

import { UseMutationOptionsCustomErrorType } from '@mwell-healthhub/commons';
import { unlinkMembership, verifyMembership } from '../../services';

export const useVerifyMembership = (mutationOptions: UseMutationOptionsCustomErrorType) => {
  return useMutation(verifyMembership, mutationOptions);
};

export const useUnlinkMembership = (mutationOptions: UseMutationOptionsCustomErrorType) => {
  return useMutation(unlinkMembership, mutationOptions);
};
