import { GetMemberResponseDto } from '@healthhub/api-lib';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GET_MEMBERSHIPS } from '../../constants';
import { getMemberships, GetMembersParams } from '../../services';

export const useGetMemberships = (
  params: GetMembersParams,
  options?: UseQueryOptions<GetMemberResponseDto>,
) =>
  useQuery<GetMemberResponseDto>([GET_MEMBERSHIPS, params], () => getMemberships(params), {
    refetchOnWindowFocus: false,
    ...options,
  });
