import { TagsApi } from '@healthhub/api-lib';

import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';

const tagsApi = HealthHubApiClient.use(TagsApi);

export type GetTagsParams = {
  id?: string;
  name?: string;
  userId?: string;
  limit?: number;
  page?: number;
};

export async function getTags(params?: GetTagsParams) {
  try {
    const response = await tagsApi.tagControllerGetTags(
      params?.id,
      params?.name,
      params?.userId,
      params?.limit,
      params?.page,
    );

    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}
