import { ComponentType, Dispatch, createContext, useReducer } from 'react';

import { TagResponseDto, User } from '@healthhub/api-lib';
import dynamic from 'next/dynamic';

const VerifyMembership = dynamic(
  () => import('../components/molecules/memberships/VerifyMembership'),
  {
    ssr: false,
  },
);
const ViewTags = dynamic(() => import('../components/molecules/memberships/ViewTags'), {
  ssr: false,
});

export const membershipsComponentsMap = {
  verifyMembershipPage: VerifyMembership,
  viewTagsPage: ViewTags,
};

type FormState = {
  MembershipPageToRender: ComponentType<any>;
  selectedTag: TagResponseDto | null;
  user: User | null;
};

export enum VerifyMembershipFormEnum {
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  MEMBERSHIP_NO = 'membershipNo',
}

export type VerifyMembershipFieldsType = {
  [VerifyMembershipFormEnum.LAST_NAME]: string;
  [VerifyMembershipFormEnum.BIRTH_DATE]: Date;
  [VerifyMembershipFormEnum.MEMBERSHIP_NO]: string;
};

const initialState: FormState = {
  MembershipPageToRender: membershipsComponentsMap['viewTagsPage'],
  selectedTag: null,
  user: null,
};

type MembershipsAction = {
  type: string;
  payload?: any;
};

type ContextProps = {
  state: FormState;
  dispatch: Dispatch<MembershipsAction>;
};

export enum MembershipsActionEnum {
  SET_SELECTED_TAG = 'SET_SELECTED_TAG',
  SET_USER = 'SET_USER',
  SET_PAGE_TO_RENDER = 'SET_PAGE_TO_RENDER',
  RESET_TO_INITIAL_MEMBERSHIPS_PAGE = 'RESET_TO_INITIAL_MEMBERSHIPS_PAGE',
}

const reducerFunction = (state: FormState, action: MembershipsAction): FormState => {
  switch (action.type) {
    case MembershipsActionEnum.SET_SELECTED_TAG: {
      return {
        ...state,
        selectedTag: action.payload,
      };
    }
    case MembershipsActionEnum.SET_USER: {
      const user: User = action.payload;

      return {
        ...state,
        user,
      };
    }
    case MembershipsActionEnum.SET_PAGE_TO_RENDER: {
      return {
        ...state,
        MembershipPageToRender: action.payload,
      };
    }
    case MembershipsActionEnum.RESET_TO_INITIAL_MEMBERSHIPS_PAGE: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const membershipsSessionStorageKey = 'membershipsContextState';

export const MembershipsContext = createContext<ContextProps | undefined>(undefined);

export const MembershipsContextProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducerFunction, initialState);

  const value = { state, dispatch };

  return <MembershipsContext.Provider value={value}>{children}</MembershipsContext.Provider>;
};
